import React, {Fragment, useMemo} from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {AnalysisResultIndicator, AnalysisResultItem} from 'api/analysisApi'
import {Colors} from 'components/common/useBaseStyle'
import {HorizontalImageGraph} from 'components/molcules/Report/RawData/HorizontalImageGraph'
import ReportHeader from 'components/Report/ReportHeader'
import {Tab, TabPanel, Tabs} from 'components/Tab'
import styled from 'styled-components'
import {WaveGraph} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaRawData'
import WaveGraphPropertyFactory, {
  WaveGraphPropertySourceType,
} from 'lib/GraphSource'

interface RawDataCleanedProps {
  items: AnalysisResultItem[]
}

function RawDataCleaned({items}: RawDataCleanedProps) {
  const [tabIndex, setTabIndex] = React.useState(0)
  const [cleaned, badEpoch] = items

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={handleTabChanged}
        indicatorColor='secondary'
        textColor='secondary'
      >
        {cleaned && <Tab label='Cleaned data' />}
        {badEpoch && <Tab label='Cleaned data with bad epoch' />}
      </Tabs>

      {cleaned && (
        <TabPanel value={tabIndex} index={0}>
          <HorizontalImageGraph xAxisImg={cleaned.imgPath} />
        </TabPanel>
      )}
      {badEpoch && (
        <TabPanel value={tabIndex} index={cleaned ? 1 : 0}>
          <HorizontalImageGraph xAxisImg={badEpoch.imgPath} />
        </TabPanel>
      )}
    </div>
  )
}

const StyledRawData = styled.div`
  & .MuiAccordion-root {
    border: 1px solid ${Colors.border};
  }

  & .Image {
    padding: 30px;
    & img {
      margin: 0 auto;
      vertical-align: middle;
    }
  }

  & img {
    width: 100%;
    height: auto;
    margin: 0;
    border: 0;
  }
`

interface RawDataProps {
  indicator: AnalysisResultIndicator
  items: AnalysisResultItem[]
}

function RawData({indicator, items}: RawDataProps) {
  const webItems = useMemo(
    () => items.filter((item) => item.imgType === 'WEB'),
    [items],
  )

  if (webItems.length === 0) return null
  const [full, cleaned, badEpoch, reliability] = webItems
  const renderRawData = () => {
    if (full) {
      if (full.rawDataNamePath) {
        return (
          <WaveGraph
            raw={{
              url: full.rawDataNamePath,
            }}
            image={full.rawDataPath}
            propertyGuide={WaveGraphPropertyFactory.sourceOfType(
              WaveGraphPropertySourceType.Raw,
              JSON.parse(full.rejectedTime ? full.rejectedTime : []),
            )}
          />
        )
      }
      return <HorizontalImageGraph xAxisImg={full.imgPath} />
    }
    return null
  }
  const renderCleanedData = () => {
    if (cleaned || badEpoch) {
      if (full.rawDataNamePath) {
        return (
          <Fragment>
            <div style={{width: '100%', padding: '16px'}}>
              <h3>Cleaned data</h3>
            </div>
            <WaveGraph
              raw={{
                url: full.rawDataNamePath,
              }}
              image={full.rawDataPath}
              propertyGuide={WaveGraphPropertyFactory.sourceOfType(
                WaveGraphPropertySourceType.Cleaned,
              )}
            />
          </Fragment>
        )
      }
      return <RawDataCleaned items={[cleaned, badEpoch]} />
    }
    return null
  }
  return (
    <StyledRawData>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      {renderRawData()}
      {renderCleanedData()}
      {reliability && (
        <div className='Image'>
          <Accordion defaultExpanded={false} elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              * Split-half & Test-retest reliability
            </AccordionSummary>
            <AccordionDetails>
              <img
                src={reliability.imgPath}
                alt='reliability about cleaned data'
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </StyledRawData>
  )
}

export default RawData
