import {useState} from 'react'
import {
  DropDownStructure,
  heightOptions,
  montageOptions,
  widthOptions,
} from 'components/molcules/Report/RawData/RawDataStyles'

export default function BioGraphCanvasViewModel() {
  const [selectedOptionWidth, setSelectedOptionWidth] =
    useState<DropDownStructure>(widthOptions[2])
  const [selectedOptionHeight, setSelectedOptionHeight] =
    useState<DropDownStructure>(heightOptions[4])
  const [montageOption, setMontageOption] = useState<DropDownStructure>(
    montageOptions[0],
  )
  const [scrollX, setScrollX] = useState<number>(0)

  const [playState, setPlayState] = useState(false)
  const [resetValue, setResetValue] = useState('')
  const [time, setTime] = useState<string>('00:00')

  return {
    montageOption,
    selectedOptionWidth,
    selectedOptionHeight,
    scrollX,
    playState,
    resetValue,
    time,

    setMontageOption,
    setSelectedOptionWidth,
    setSelectedOptionHeight,
    setScrollX,
    setPlayState,
    setResetValue,
    setTime,
  }
}
