interface EegIndividualTimesRejectedResponse {
  label: string //  "ASR",
  time_section: number[] // [976, 1151]
}

const AXIS_SIZE = 3
const GRID_LINE_COLOR: number[] = [0.0, 0.0, 0.97, 0.2]
const SUB_GRID_LINE_COLOR: number[] = [0.0, 0.0, 1, 0.08]
const GRAPH_STROKE_COLOR_1: number[] = [0.0, 0.0, 0.0, 1]
const GRAPH_STROKE_COLOR_2: number[] = [0.15, 0.14, 0.74, 1]

const BAD_EPOCH_1: number[] = [1, 0, 0, 0.3]
const BAD_EPOCH_2: number[] = [0, 0.15, 0.3, 0.3]

export enum GraphLineColor {
  RawData,
  CleanedData,
}

export enum BadEpochLabelType {
  BadEpoch = 'Bad epoch',
  Asr = 'ASR',
}

const colorForGraph = (colorType: GraphLineColor) => {
  if (colorType === GraphLineColor.RawData) {
    return GRAPH_STROKE_COLOR_1
  }
  if (colorType === GraphLineColor.CleanedData) {
    return GRAPH_STROKE_COLOR_2
  }
  return [0, 0, 0, 0]
}

export const colorForBadEpoch = (label: BadEpochLabelType) => {
  if (label === BadEpochLabelType.Asr) {
    return BAD_EPOCH_1
  }
  return BAD_EPOCH_2
}

const drawGridLines = (
  gl: WebGLRenderingContext,
  coord: number,
  shaderProgram: WebGLProgram,
  secPerCanvas: number,
  channelCount: number,
) => {
  const positions = []
  const verticalGridSize = 1
  const verticalGridSpacing = 2 / secPerCanvas // 30 seconds per canvas or remaing seconds for last canvas
  const horizontalGridSize = 1
  const horizontalGridSpacing = 2 / (channelCount + 1)

  for (
    let i = -verticalGridSize;
    i <= verticalGridSize;
    i += verticalGridSpacing
  ) {
    // Draw vertical lines
    positions.push(i, -verticalGridSize, 0)
    positions.push(i, verticalGridSize, 0)
  }

  for (
    let i = -horizontalGridSize;
    i <= horizontalGridSize;
    i += horizontalGridSpacing
  ) {
    // Draw horizontal lines
    positions.push(-horizontalGridSize, i, 0)
    positions.push(horizontalGridSize, i, 0)
  }

  // Add the grid vertices to the vertex buffer
  const vertexBuffer = gl.createBuffer()
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer)
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW)
  gl.vertexAttribPointer(coord, 3, gl.FLOAT, false, 0, 0)

  const uColor = gl.getUniformLocation(shaderProgram, 'u_color')
  // set color of the shader
  gl.uniform4fv(uColor, GRID_LINE_COLOR)

  // Draw the grid
  gl.drawArrays(gl.LINES, 0, positions.length / AXIS_SIZE)

  // for sub seconds vertical grid
  const subPositions = []
  const subVerticalGridSpacing = 2 / 5 / secPerCanvas

  for (
    let i = -verticalGridSize;
    i <= verticalGridSize;
    i += subVerticalGridSpacing
  ) {
    // Draw vertical lines
    subPositions.push(i, -verticalGridSize, 0)
    subPositions.push(i, verticalGridSize, 0)
  }

  const subVertexBuffer = gl.createBuffer()
  gl.bindBuffer(gl.ARRAY_BUFFER, subVertexBuffer)
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(subPositions), gl.STATIC_DRAW)
  gl.vertexAttribPointer(coord, 3, gl.FLOAT, false, 0, 0)

  const subColor = gl.getUniformLocation(shaderProgram, 'u_color')
  // set color of the shader
  gl.uniform4fv(subColor, SUB_GRID_LINE_COLOR)

  // Draw the grid
  gl.drawArrays(gl.LINES, 0, subPositions.length / AXIS_SIZE)
}

const drawBadEpochs = (
  gl: WebGLRenderingContext,
  coord: number,
  shaderProgram: WebGLProgram,
  secPerCanvas: number,
  index: number,
  timesRejectedData: EegIndividualTimesRejectedResponse[],
) => {
  const size = 2 // 2 components per iteration
  const type = gl.FLOAT // the data is 32bit floats
  const normalize = false // don't normalize the data
  const stride = 0 // 0 = move forward size * sizeof(type) each iteration to get the next position
  const offset = 0 // start at the beginning of the buffer

  const limit = 250 * secPerCanvas
  const leftLimit = 250 * 30 * index
  const rightLimit = leftLimit + limit

  const rectBuffer = gl.createBuffer()
  gl.bindBuffer(gl.ARRAY_BUFFER, rectBuffer)
  gl.vertexAttribPointer(coord, size, type, normalize, stride, offset)

  timesRejectedData.forEach((t) => {
    const [left, right] = t.time_section
    if (right < leftLimit || rightLimit < left) {
      return
    }

    const y1 = -1
    const y2 = 1
    const x1 =
      leftLimit <= left
        ? (left - leftLimit) / ((250 * secPerCanvas) / 2) - 1
        : -1
    const x2 =
      right < rightLimit
        ? (right - leftLimit) / ((250 * secPerCanvas) / 2) - 1
        : 1

    const trianglePositions = [x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]
    const rectColor = gl.getUniformLocation(shaderProgram, 'u_color')
    gl.uniform4fv(rectColor, colorForBadEpoch(t.label as BadEpochLabelType))
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array(trianglePositions),
      gl.STATIC_DRAW,
    )

    const primitiveType = gl.TRIANGLES
    const count = trianglePositions.length / size
    gl.drawArrays(primitiveType, offset, count)
  })
}

const WEBGLContextManipulation = (
  gl: WebGLRenderingContext,
  vertices: number[][],
  canvas: HTMLCanvasElement,
  colorType: GraphLineColor = GraphLineColor.RawData,
  index: number,
  showBadEpoch: boolean,
  timesRejectedData?: EegIndividualTimesRejectedResponse[],
) => {
  if (!canvas || !gl || !vertices) return

  /* =================== canvas sizes ==================== */
  const {width, height} = canvas

  gl.bindBuffer(gl.ARRAY_BUFFER, null)

  /* ================== Shaders ==================== */

  const vertCode =
    'attribute vec3 coordinates;' +
    'void main(void) {' +
    ' gl_Position = vec4(coordinates, 1.0);' +
    '}'

  const vertShader = gl.createShader(gl.VERTEX_SHADER)

  const fragCode =
    'precision mediump float;' +
    'uniform vec4 u_color;' +
    'void main(void) {' +
    '  gl_FragColor = u_color;' +
    '}'

  const fragShader = gl.createShader(gl.FRAGMENT_SHADER)

  const shaderProgram = gl.createProgram()

  if (vertShader && fragCode && shaderProgram && fragShader !== null) {
    gl.shaderSource(vertShader, vertCode)

    gl.compileShader(vertShader)

    gl.shaderSource(fragShader, fragCode)

    gl.compileShader(fragShader)

    gl.attachShader(shaderProgram, vertShader)

    gl.attachShader(shaderProgram, fragShader)

    gl.linkProgram(shaderProgram)

    gl.useProgram(shaderProgram)

    /* ======= Associating shaders to buffer objects ====== */

    const coord = gl.getAttribLocation(shaderProgram, 'coordinates')

    gl.enableVertexAttribArray(coord)

    /* ============ Drawing the triangle ============= */

    gl.clearColor(0.0, 0.0, 0.0, 0.0)

    gl.enable(gl.DEPTH_TEST)

    // eslint-disable-next-line
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)

    gl.viewport(0, 0, width, height)

    const channelCount = vertices.length
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < channelCount; i++) {
      const numberOfVertices = vertices[i].length / AXIS_SIZE
      const vertexBuffer = gl.createBuffer()
      gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer)
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(vertices[i]),
        gl.STATIC_DRAW,
      )
      gl.vertexAttribPointer(coord, 3, gl.FLOAT, false, 0, 0)

      const uColor = gl.getUniformLocation(shaderProgram, 'u_color')
      // set color of the shader
      gl.uniform4fv(uColor, colorForGraph(colorType))

      gl.drawArrays(gl.LINE_STRIP, 0, numberOfVertices) // numberOfVertices is the vertices, you wann to draw over canvas
    }
    const secPerCanvas = vertices[0].length / 3 / 250
    drawGridLines(gl, coord, shaderProgram, secPerCanvas, channelCount)
    if (timesRejectedData && showBadEpoch) {
      drawBadEpochs(
        gl,
        coord,
        shaderProgram,
        secPerCanvas,
        index,
        timesRejectedData,
      )
    }
  }
}

export default WEBGLContextManipulation
