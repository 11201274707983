import {range} from 'helpers/commonHelper'

export enum MontageFilterType {
  NONE,
  CAR,
  BANANA,
  TRANSVERSE,
  Component,
}

export const MontageChannelLabels = {
  [MontageFilterType.NONE]: [
    {label: 'Fp1-Ref'},
    {label: 'Fp2-Ref'},
    {label: 'F7-Ref'},
    {label: 'F3-Ref'},
    {label: 'Fz-Ref'},
    {label: 'F4-Ref'},
    {label: 'F8-Ref'},
    {label: 'T3-Ref'},
    {label: 'C3-Ref'},
    {label: 'Cz-Ref'},
    {label: 'C4-Ref'},
    {label: 'T4-Ref'},
    {label: 'T5-Ref'},
    {label: 'P3-Ref'},
    {label: 'Pz-Ref'},
    {label: 'P4-Ref'},
    {label: 'T6-Ref'},
    {label: 'O1-Ref'},
    {label: 'O2-Ref'},
  ],
  [MontageFilterType.CAR]: [
    {label: 'Fp1-Av'},
    {label: 'Fp2-Av'},
    {label: 'F7-Av'},
    {label: 'F3-Av'},
    {label: 'Fz-Av'},
    {label: 'F4-Av'},
    {label: 'F8-Av'},
    {label: 'T3-Av'},
    {label: 'C3-Av'},
    {label: 'Cz-Av'},
    {label: 'C4-Av'},
    {label: 'T4-Av'},
    {label: 'T5-Av'},
    {label: 'P3-Av'},
    {label: 'Pz-Av'},
    {label: 'P4-Av'},
    {label: 'T6-Av'},
    {label: 'O1-Av'},
    {label: 'O2-Av'},
  ],

  [MontageFilterType.BANANA]: [
    {label: 'Fp1-F3'},
    {label: 'F3-C3'},
    {label: 'C3-P3'},
    {label: 'P3-O1'},
    {label: 'Fp2-F4'},
    {label: 'F4-C4'},
    {label: 'C4-P4'},
    {label: 'P4-O2'},
    {label: 'Fp1-F7'},
    {label: 'F7-T3'},
    {label: 'T3-T5'},
    {label: 'T5-O1'},
    {label: 'Fp2-F8'},
    {label: 'F8-T4'},
    {label: 'T4-T6'},
    {label: 'T6-O2'},
    {label: 'Fz-Cz'},
    {label: 'Cz-Pz'},
  ],

  [MontageFilterType.TRANSVERSE]: [
    {label: 'F7-Fp1'},
    {label: 'Fp1-Fp2'},
    {label: 'Fp2-F8'},
    {label: 'F7-F3'},
    {label: 'F3-Fz'},
    {label: 'Fz-F4'},
    {label: 'F4-F8'},
    {label: 'T3-C3'},
    {label: 'C3-Cz'},
    {label: 'Cz-C4'},
    {label: 'C4-T4'},
    {label: 'T5-P3'},
    {label: 'P3-Pz'},
    {label: 'Pz-P4'},
    {label: 'P4-T6'},
    {label: 'T5-O1'},
    {label: 'O1-O2'},
    {label: 'O2-T6'},
  ],

  [MontageFilterType.Component]: range(1, 19).map((it) => {
    return {label: it.toString()}
  }),
}

const BANANA_CHANNEL_NUMBER = [
  [0, 3, 8, 13, 1, 5, 10, 15, 0, 2, 7, 12, 1, 6, 11, 16, 4, 9],
  [3, 8, 13, 17, 5, 10, 15, 18, 2, 7, 12, 17, 6, 11, 16, 18, 9, 14],
]
const TRANSVERSE_CHANNEL_NUMBER = [
  [2, 0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 12, 13, 14, 15, 12, 17, 18],
  [0, 1, 6, 3, 4, 5, 6, 8, 9, 10, 11, 13, 14, 15, 16, 18, 18, 16],
]

function filterWithReference(reference: number[][], source: number[][]) {
  const filter: number[][] = []
  for (let i = 0; i < 18; i += 1) {
    const arr: number[] = []
    for (let position = 0; position < source[0].length; position += 1) {
      const a = reference[0][i]
      const b = reference[1][i]
      arr.push(source[a][position] - source[b][position])
    }
    filter.push(arr)
  }
  return filter
}

export interface MontageFilterImpl {
  filtering: (source: number[][]) => number[][]
}

class NoneFilter implements MontageFilterImpl {
  filtering = (source: number[][]) => {
    return source
  }
}

class CarFilter implements MontageFilterImpl {
  filtering = (source: number[][]) => {
    for (
      let signalCount = 0;
      signalCount < source[0].length;
      signalCount += 1
    ) {
      const average = source.reduce((a, b) => a + b[signalCount], 0) / 19
      for (let chartSize = 0; chartSize < 19; chartSize += 1) {
        source[chartSize][signalCount] -= average
      }
    }
    return source
  }
}

class BananaFilter implements MontageFilterImpl {
  filtering = (source: number[][]) => {
    return filterWithReference(BANANA_CHANNEL_NUMBER, source)
  }
}

class TransverseFilter implements MontageFilterImpl {
  filtering = (source: number[][]) => {
    return filterWithReference(TRANSVERSE_CHANNEL_NUMBER, source)
  }
}

class MontageFilter {
  static filterOfType(type: MontageFilterType): MontageFilterImpl {
    if (type === MontageFilterType.BANANA) {
      return new BananaFilter()
    }
    if (type === MontageFilterType.CAR) {
      return new CarFilter()
    }
    if (type === MontageFilterType.TRANSVERSE) {
      return new TransverseFilter()
    }
    return new NoneFilter()
  }
}

export default MontageFilter
